import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutFR from "../composants/layout/fr/LayoutFR"
import { obtenirPage } from "../js/client-es/utils"

import fragmentsImages from "../js/client-es/fragments/fragments-images"
import fragmentsPages from "../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageMentionsLegales: allPagesCompilationYaml(
      filter: { code: { eq: "mentionsLegales" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function MentionsLegales() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageMentionsLegales")

  return (
    <LayoutFR page={page}>
      <p>
        En utilisant ce site Internet vous acceptez sans réserves les présentes
        modalités.
      </p>
      <h3>Auteur</h3>
      <p>Bastien Foucher</p>
      <p>SIRET : 800 314 817 00027</p>
      <h3>Hébergeur</h3>
      <p>
        OVH
        <br />
        2 rue Kellermann - 59100 Roubaix
        <br />
        France
        <br />
        www.ovh.fr
      </p>
      <h3>Données personnelles</h3>
      <p>
        Ce site est conforme à la RGPD : il n’utilise pas de cookies et ne
        collecte pas de données individuelles. Seules des statistiques de
        fréquentation totales par page sont réalisées. Néanmoins en cas d’achat
        (livre électronique, tirage photographique), les coordonnées des clients
        sont conservées afin d’assurer le suivi et le traitement des commandes.
        Elles ne sont en aucun cas diffusées à des tiers.
      </p>
      <iframe
        style="border: 0; height: 150px; width: 600px;"
        src="https://matomo.bastienfoucher.com/index.php?module=CoreAdminHome&action=optOut&language=fr&backgroundColor=&fontColor=4f4f4f&fontSize=&fontFamily=Arial%2C%20Helvetica%2C%20sans-serif"
      ></iframe>
      <h3>Responsabilité</h3>
      <p>
        Les internautes assument l’entière responsabilité de leur utilisation de
        ce site internet. L’auteur décline toute responsabilité concernant les
        informations disponibles sur le site et les utilisations qui peuvent en
        être faites. En aucun cas l’auteur ne pourra être tenu pour responsable
        des dommages de toute nature, directs ou indirects, qui résulteraient de
        l’utilisation de ce site ou en relation avec ce site.
      </p>
      <h3>Propriété intellectuelle</h3>
      <p>
        Ce site Internet ainsi que l’ensemble de son contenu (photos, logos,
        marques et information de toute nature y figurant) sont protégés par le
        Code de la propriété intellectuelle. Aucune reproduction des articles et
        des images présentes sur ce site ne peut être réalisée sans le
        consentement de l’auteur.
      </p>
    </LayoutFR>
  )
}
